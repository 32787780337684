import { Entry } from "contentful";
import { useEffect, useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Editorials from "~/types/editorials";
import Constants from "~/utils/constants";
import { assertEditorialType, isActiveEntry } from "~/utils/editorial-utils";

import { CarouselArrows, CarouselWrapper, updateArrowStatus } from "../carousel-utils/carousel-utils";
import ServiceCard from "./service-card";
import styles from "./services-carousel.module.scss";

type Props = {
  entry: Entry<unknown>;
};

export default function ServicesCarousel({ entry }: Props) {
  const [arrowsStatus, setArrowsStatus] = useState({ arrowL: false, arrowR: false });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();

  useEffect(() => {
    if (swiperInstance) {
      setArrowsStatus(updateArrowStatus(swiperInstance));
    }
  }, [swiperInstance]);

  assertEditorialType<Editorials.ServicesCarousel>(entry, "servicesCarousel");

  if (!isActiveEntry(entry) || !entry.fields.services || entry.fields.services.length === 0) return null;

  return (
    <CarouselWrapper entry={entry}>
      <Swiper
        onSwiper={setSwiperInstance}
        onTransitionEnd={(currentSwiper) => setArrowsStatus(updateArrowStatus(currentSwiper))}
        className={styles.swiper}
        centeredSlidesBounds
        grabCursor
        slidesPerView={2}
        centeredSlides={entry.fields.services.length > 2}
        initialSlide={1}
        spaceBetween={20}
        centerInsufficientSlides
        speed={Constants.SWIPER_TRANSITION_DURATION}
        breakpoints={{
          640: {
            spaceBetween: 12,
            slidesPerView: 4,
            initialSlide: 2,
            centeredSlides: entry.fields.services.length > 4,
          },
          900: {
            spaceBetween: 80,
            slidesPerView: 4,
            initialSlide: 2,
            centeredSlides: entry.fields.services.length > 4,
          },
          1280: {
            spaceBetween: 80,
            slidesPerView: 4,
            initialSlide: 2,
            centeredSlides: entry.fields.services.length > 4,
          },
          1920: {
            spaceBetween: 166,
            slidesPerView: 5,
            initialSlide: 2,
            centeredSlides: entry.fields.services.length > 5,
          },
        }}
      >
        {entry.fields.services?.filter(isActiveEntry).map((serviceCard, i) => (
          <SwiperSlide key={i} className={styles.slide}>
            <ServiceCard entry={serviceCard} />
          </SwiperSlide>
        ))}
      </Swiper>
      {swiperInstance && <CarouselArrows swiperInstance={swiperInstance} arrowsStatus={arrowsStatus} />}
    </CarouselWrapper>
  );
}
