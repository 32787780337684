import classNames from "classnames";
import { Entry } from "contentful";

import EscapeNewLine from "~/components/common/escape-new-line";
import HighlightedText from "~/components/common/highlighted-text";
import MediaAsset from "~/components/common/media-asset";
import Editorials from "~/types/editorials";
import contentfulUtils from "~/utils/contentful-utils";

import EditorialLink from "../editorial-link/editorial-link";
import styles from "./service-card.module.scss";

type Props = {
  entry: Entry<Editorials.Card>;
};

export default function ServiceCard({ entry }: Props) {
  const inspectorMode = contentfulUtils.useInspectorMode(entry);

  return (
    <div className={styles.service}>
      {entry.fields.media ? (
        <div className={styles.serviceImg}>
          <MediaAsset
            entry={entry.fields.media}
            fill
            sizes="(max-width: 640px) 42vw, (max-width: 900px) 22vw, (max-width: 1280px) 18vw, 20vw"
          />
        </div>
      ) : null}
      <p
        {...inspectorMode?.getProps("title")}
        className={contentfulUtils.isHighlightText(entry.fields.title) ? styles.titleHighlighted : styles.title}
      >
        <HighlightedText text={entry.fields.title} />
      </p>
      <p {...inspectorMode?.getProps("description")} className={styles.desc}>
        <EscapeNewLine text={entry.fields.description} />
      </p>
      {entry.fields.link ? (
        <div className={styles.linkWrapper}>
          <EditorialLink entry={entry.fields.link} lookAsButton={"secondary"} className={styles.link} />
        </div>
      ) : null}
    </div>
  );
}
